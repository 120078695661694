import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";

import { auth, firestore, functions } from "../../plugins/firebase";
import { User } from "../../model/auth";
import { AuthDelegate } from "../../../../shared/github";
import {
  RefreshTokenResponse,
  UserRepos,
  User as DatabaseUser
} from "../../../../shared/types";
import { userPath, userReposPath } from "../../../../shared/database";

@Module({
  name: "auth"
})
export default class AuthModule extends VuexModule {
  public user: User | null = null;
  public databaseUser: DatabaseUser | null = null;
  public userRepos: UserRepos | null = null;
  public isRefreshing: boolean = false;

  static getDelegate(module: AuthModule): AuthDelegate {
    return {
      getToken: () => module.assertUser.githubToken,
      getAuthenticationHeader: () => `token`,
      getExpiry: () => module.assertUser.githubExpiry,
      refreshAuth: () => module.refreshGithubAuth()
    };
  }

  @Action({ rawError: true })
  public async loadDatabaseUser() {
    if (!this.user) {
      return;
    }

    const id = this.user.uid;

    const db = firestore();
    const ref = doc(db, userPath({ id }));
    const data = (await getDoc(ref)).data();
    if (data) {
      this.context.commit("setDatabaseUser", data as DatabaseUser);
    }
  }

  @Action({ rawError: true })
  public async loadUserRepos(force?: boolean) {
    if (!this.user) {
      return;
    }

    if (this.userRepos != null && !force) {
      return;
    }

    const id = this.user.uid;

    const db = firestore();
    const ref = doc(db, userReposPath({ id }));
    const data = (await getDoc(ref)).data();
    if (data) {
      this.context.commit("setUserRepos", data as UserRepos);
    }
  }

  @Mutation
  setDatabaseUser(databaseUser: DatabaseUser | null) {
    this.databaseUser = databaseUser;
  }

  @Mutation
  setUserRepos(userRepos: UserRepos | null) {
    this.userRepos = userRepos;
  }

  @Mutation
  setIsRefreshing(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
  }

  @Mutation
  restoreFromLocalStorage() {
    const userString = localStorage.getItem("user");
    if (userString == null) {
      this.user = null;
      console.log("No user found");
      return;
    }

    try {
      const user = JSON.parse(userString) as User;
      this.user = user;
      console.log("User restored");
    } catch (e) {
      console.log("Failed to parse user JSON");
      this.user = null;
    }
  }

  @Mutation
  setUser(u: User | null) {
    console.log(`auth.setUser(${u ? u.uid : null})`);
    this.user = u;

    const dbUserMismatch =
      u && this.databaseUser && u.username !== this.databaseUser.login;
    if (!u || dbUserMismatch) {
      this.databaseUser = null;
    }

    localStorage.setItem("user", JSON.stringify(this.user));
  }

  @Mutation
  updateGithubToken(opts: { githubToken: string; githubExpiry: number }) {
    console.log("updateGithubToken", `expiry=${opts.githubExpiry}`);

    this.user!.githubToken = opts.githubToken;
    this.user!.githubExpiry = opts.githubExpiry;

    // Unlock refreshing
    this.isRefreshing = false;

    // TODO(polish): Could I just call setUser?
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  get assertUser(): User {
    return this.user!;
  }

  get signedIn(): boolean {
    return !!this.user;
  }

  get userRepoNames(): string[] {
    if (this.userRepos) {
      return this.userRepos.repo_names;
    }

    return [];
  }

  @Action({ rawError: true })
  async startSignOut() {
    await auth().signOut();
  }

  @Action({ rawError: true })
  async refreshGithubAuth() {
    console.log(`refreshGithubAuth(${this.isRefreshing})`);

    // If there is another refresh in progress, wait for it to finish.
    if (this.isRefreshing) {
      return new Promise<void>((resolve, reject) => {
        const interval = 100;
        const timeout = 30000;
        let elapsed = 0;

        const intervalId = setInterval(() => {
          if (!this.isRefreshing) {
            console.log(`refreshGithubAuth: waited ${elapsed}ms`);
            clearInterval(intervalId);
            resolve();
            return;
          }

          if (elapsed > timeout) {
            console.warn(`refreshGithubAuth: waited more than ${timeout}ms`);
            reject();
            return;
          }

          elapsed += interval;
        }, interval);
      });
    }

    // Place a lock on refreshing, and then refresh the token
    this.context.commit("setIsRefreshing", true);
    try {
      const tokenRes = await httpsCallable(functions(), "api/getGithubToken")();
      const data = tokenRes.data as RefreshTokenResponse;

      this.context.commit("updateGithubToken", {
        githubToken: data.access_token,
        githubExpiry: data.access_token_expires
      });
    } catch (e) {
      console.warn("Error refreshing auth", e);
    }
    this.context.commit("setIsRefreshing", false);
  }
}
