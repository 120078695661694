





import { isVideoFile } from "@/plugins/binary";
import { decodeLocator } from "@/plugins/locator";
import { getPrismLangCode } from "@/plugins/prism";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { baseUrl } from "../../../../shared/config";

// See: https://github.com/markedjs/marked/issues/1692
const marked = require("marked");
const Prism = require("prismjs");
const DOMPurify = require("dompurify");

// See: https://github.com/cure53/DOMPurify/issues/317#issuecomment-698800327
DOMPurify.removeHooks("afterSanitizeAttributes");
DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    const href = node.href as string | undefined;

    // For links which don't point to CodeApprove, add these attributes.
    // We don't want to add them to CodeApprove links because they should be
    // handled by the router locally.
    if (!!href && !href.startsWith(baseUrl())) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  }
});

marked.setOptions({
  gfm: true,
  breaks: true,
  highlight: (code: any, lang: any) => {
    const langCode = getPrismLangCode(lang);
    const prismLang = Prism.languages[langCode];
    if (prismLang) {
      return Prism.highlight(code, prismLang, langCode);
    } else {
      return Prism.highlight(code, Prism.languages.markup, "markup");
    }
  }
});

// Match the start of the line or whitespace, followed by and @
// and then word chars or dashes.
const RE_MENTION = /(^|\s)@[\w-]+/g;

// GitHub issue or PR link
// Groups:
//  1 - owner
//  2 - repo
//  3 - issues | pull
//  4 - number
const RE_GITHUB_LINK = /https:\/\/github.com\/([^/]+?)\/([^/]+?)\/(issues|pull)\/([0-9]+)/;

function isVideoLink(href: string) {
  const url = new URL(href);
  const segments = url.pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  return isVideoFile(lastSegment);
}

const renderer = {
  link(href: string | null, title: string | null, text: string | null) {
    const defaultLink = `<a href="${href}" title="${title || ""}">${text ||
      ""}</a>`;

    if (href) {
      // Detect relative links by the lack of protocol
      if (href.indexOf("//") < 0) {
        return defaultLink;
      }

      if (isVideoLink(href)) {
        return `<video src="${href}" controls></video>`;
      }

      // We only change links when the href and the text are identical,
      // otherwise we are messing with legitimate markdown links
      if (href === text) {
        // Link to GitHub
        const ghMatch = href.match(RE_GITHUB_LINK);
        if (ghMatch) {
          return `<a href="${href}">${ghMatch[2]}#${ghMatch[4]}</a>`;
        }

        // Link to CodeApprove line
        if (href.startsWith(baseUrl()) && href.includes("#line-")) {
          const hash = href.split("#line-")[1];
          const decoded = decodeLocator(hash);
          if (decoded) {
            return `<a href="${href}">${decoded?.n}#L${decoded.l}</a>`;
          }
        }
      }
    }

    return defaultLink;
  },
  text(text: string) {
    const mentionMatch = text.match(RE_MENTION);
    if (mentionMatch) {
      let newText = text;
      for (const m of mentionMatch) {
        newText = newText.replace(m, `<b>${m}</b>`);
      }
      return newText;
    }

    return text;
  },
  codespan(text: string) {
    return `<code class="inline">${text}</code>`;
  }
};

marked.use({ renderer });

@Component
export default class MarkdownContent extends Vue {
  @Prop() content!: string;
  @Prop({ default: false }) plaintext!: boolean;

  public htmlContent = "";
  public plaintextContent = "";

  mounted() {
    this.onContentChanged();
  }

  @Watch("content")
  public onContentChanged() {
    // In order to safely use v-html we must use DOMPurify (or some other sanitizer)
    // to make the rendered content safe to prevent simple attacks like:
    // <img src=1 href=1 onerror="javascript:alert(1)"></img>
    this.htmlContent = DOMPurify.sanitize(marked(this.content), {
      USE_PROFILES: { html: true }
    });

    // Create a fake element to use 'innerText'
    //
    // Apparently it is important to actually add the element to the document.body
    // before using innerText (https://stackoverflow.com/a/55804871) so we use the #scratch
    // area to stage this.
    const tmpEl = document.createElement("div");
    tmpEl.innerHTML = this.htmlContent;
    const scratch = document.getElementById("scratch");
    (scratch || document.body).append(tmpEl);
    this.plaintextContent = tmpEl.innerText || "";
    tmpEl.remove();
  }
}
